import React from 'react';

import Draggable from 'react-draggable';

import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

import { platformImages } from '@services/utils.service';

const Notification = ({ platformName, handleCloseNotification, layoutMode = 'rectangle' }) => {
  return (
    <Draggable bounds="parent">
      <div className={`[&>div]:animate-slideUp cursor-pointer absolute z-10 bg-white ${layoutMode === 'rectangle' ? 'left-[35%]' : 'left-[33%]'} bottom-[50px] flex flex-col items-center justify-center gap-3  h-[144px] px-[54px] rounded-lg shadow-md border border-grey-100`}>
        <CloseRoundedIcon
          className="absolute top-2 right-2 cursor-pointer text-grey-700 hover:text-grey-900"
          fontSize="medium"
          onClick={handleCloseNotification}
        />
        <div className="text-lg leading-5 whitespace-nowrap text-black">
            Just type in the <span className="font-semibold">
            {['streamyard', 'restream'].includes(platformName.toLowerCase()) ? 'chat' : `${platformName} chat`}
          </span>
        </div>
        {typeof platformName === 'string' &&
              platformImages.map(
                (platform) =>
                  platformName.match(platform.name) && (
                    <img draggable="false" key={platform.name} className={platform.className} src={platform.src} alt={`${platform.name} chat`} />
                  )
              )}
      </div>
    </Draggable>
  );
};

export default Notification;
